import {UserRole} from "@prisma/client";
import {useNow} from "@vueuse/core";

export type MenuItem = {
    icon?: string
    label?: string
    badge?: string
    to: string
}

export const useMenu = () => {
    const {me} = useAuth()
    const now = useNow({
        interval: 1000
    })

    return computed<MenuItem[]>(() => {

        const nowValue = toValue(now)
        const nowTimestamp = nowValue.getTime()

        const meValue = toValue(me)
        const role = meValue?.role

        const hasActiveSubscription = (meValue?.UserSubscribtions ?? []).filter(
            userSubscription =>
                (new Date(userSubscription.startDate)).getTime() &&
                (new Date(userSubscription.startDate)).getTime() <= nowTimestamp &&
                nowTimestamp <= (
                    (new Date(userSubscription.startDate)).getTime() + (
                        userSubscription.Subscribtion.period * 1000 * 60 * 60 * 24
                    )
                )
        ).length > 0

        const res = [
            [],
            []
        ]

        res[0].push(
            {
                label: 'Home',
                to: '/',
                icon: 'i-heroicons-home'
            }
        )

        if (role === UserRole.DEFAULT || role === UserRole.ADMIN)
            res[0].push(
                {
                    label: (hasActiveSubscription || role === UserRole.ADMIN) ? 'Spreads' : 'Buy subscription',
                    to: (hasActiveSubscription || role === UserRole.ADMIN) ? '/spreads' : '/buy',
                    icon: 'i-heroicons-arrow-trending-up'
                }
            )

        res[1].push(
            {
                label: 'Settings',
                to: '/profile',
                icon: 'i-heroicons-cog-8-tooth'
            }
        )

        if (role === UserRole.ADMIN)
            res[1].push(
                {
                    label: 'Users',
                    to: '/users',
                    icon: 'i-heroicons-users'
                }
            )

        if (role === UserRole.ADMIN)
            res[1].push(
                {
                    label: 'Payments',
                    to: '/payments',
                    icon: 'i-heroicons-banknotes'
                }
            )

        return res
    })
}
