<script setup lang="ts">
</script>

<template>
  <Header/>
  <UContainer :ui="{
    base: 'mt-10 flex gap-8',
     constrained: 'max-w-full'
  }">
    <Menu/>
    <main class="flex-grow">
      <slot/>
    </main>
  </UContainer>
</template>

<style scoped>
</style>
