<script setup lang="ts">

import {useToggle} from "@vueuse/shared";

const colorMode = useColorMode()

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})

const items = computed(() => [
  [
    {
      label: 'Profile',
      icon: 'i-heroicons-user-20-solid',
      click: () => {
        const {push} = useRouter()
        push({name: 'profile'})
      },
      disabled: useRoute().name === 'profile'
    },
    {
      label: 'Theme',
      icon: toValue(isDark) ? 'i-heroicons-moon' : 'i-heroicons-sun',
      click: () => {
        const toggle = useToggle(isDark)
        toggle()
      }
    },
  ],
  [
    {
      label: 'Logout',
      icon: 'i-heroicons-arrow-right-end-on-rectangle',
      click: () => {
        const {logout} = useAuth()
        logout()
      }
    },
  ]
])

</script>

<template>
  <UDropdown :items="items" :popper="{ placement: 'bottom-start' }">
    <UButton variant="link">
      <UIcon name="i-heroicons-user-circle-20-solid" class="white w-5 h-5"/>
    </UButton>
  </UDropdown>
</template>

<style scoped></style>
