<script setup lang="ts"></script>

<template>
  <UContainer :ui="{
    base: 'px-10 py-4 flex justify-between',
     constrained: 'max-w-full'
  }">
    <HeaderLogo/>
    <HeaderAccount/>
  </UContainer>
</template>

<style scoped></style>
