<script setup lang="ts">
const menu = useMenu()
</script>

<template>
  <UVerticalNavigation :links="menu" :ui="{
    width: 'min-w-48'
  }" />
</template>

<style scoped></style>
